import { useEffect, useState } from "react";
import useEvent from "react-use-event-hook";
import "./ThermoSensorDemo.css";
import { Heatmap } from "../components/Heatmap";
import { DataContainer } from "../components/containerUtils";
import { subscribeMqtt5 } from "../utils/connect_aws.js";
import { uint16ListToThermoMap } from "../utils/thermoUtils.js";
import { isSmartPhone } from "../utils/judgeDevice.js";

const INITIAL_TEMPERATURE_MAP = [...Array(32)].map(() => {
  return [...Array(32)].map(() => 0);
});
const TOPIC_PREFIX = "sensor_demo/thermo_sensor/";

function ThermoSensorDemo(props) {
  const client = props.client;

  const [deviceList, setDeviceList] = useState(["MCU_DEV_BOARD_ES2-041"]);
  const [subscribedDeviceList, setSubscribedDeviceList] = useState([]);
  const [temperature2d, setTemperature2d] = useState({
    "MCU_DEV_BOARD_ES2-041": INITIAL_TEMPERATURE_MAP,
  });

  // トピックのサブスクライブを開始する。
  useEffect(() => {
    (async () => {
      if (client != null) {
        const currentDevice = deviceList[deviceList.length - 1];

        if (subscribedDeviceList.indexOf(currentDevice) >= 0) {
          //console.log("This device is already subscribed.");
        } else {
          if (client !== undefined) {
            await subscribeMqtt5(client, TOPIC_PREFIX + currentDevice);
            //console.log("suback: ", ret);
            setSubscribedDeviceList([...subscribedDeviceList, currentDevice]);
          }
        }
      }
    })();
  }, [client]);

  // Mqttメッセージを受け取ったときにペイロードを取得し処理するハンドラーを登録。
  const processPayload = useEvent((eventData) => {
    if (eventData.message.topicName.includes(TOPIC_PREFIX)) {
      if (eventData.message.payload) {
        const data = JSON.parse(eventData.message.payload);

        // get thing name
        const thingName = eventData.message.topicName.replace(TOPIC_PREFIX, "");
        if (deviceList.indexOf(thingName) >= 0) {
          // update thermo data
          const thermoData = uint16ListToThermoMap(data["thermo"]);
          //console.log(thermoData);
          setTemperature2d({
            ...temperature2d,
            [thingName]: thermoData,
          });
        }
      }
    }
  });

  useEffect(() => {
    if (client != null) {
      client.on("messageReceived", processPayload);
    }
  }, [client]);

  return (
    <div style={{ display: props.visibility, flexDirection: "column" }}>
      {props.isSummary ? <div></div> : <ColorBar />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {deviceList.map((device) => {
          return (
            <DataContainer
              key={`thermo-data-${device}`}
              isSummary={props.isSummary}
              width={isSmartPhone() ? 350 : 700}
              thingName={device}
              graphArea={<Heatmap data={temperature2d[device]} />}
            />
          );
        })}
      </div>
    </div>
  );
}

const ColorBar = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: 200,
          justifyContent: "space-between",
        }}
      >
        <div style={{ color: "blue" }}>20℃</div>
        <div style={{ color: "red" }}>35℃</div>
      </div>
      <img src="./color_bar.png" alt="" />
    </div>
  );
};

export default ThermoSensorDemo;
