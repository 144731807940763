import "./containerUtils.css";

export function DataContainer(props) {
  return (
    <div
      className="DataContainer"
      style={{
        width: `${props.width}px`,
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <div className="TopBar">
        <div className="StationName">{props.thingName}</div>
      </div>
      <div className="Divider" />
      {props.graphArea !== undefined ? props.graphArea : <></>}
      <div style={{ height: "20px" }}></div>
      <div style={{ width: "100%", overflow: "auto", maxHeight: "180px" }}>
        {props.tableArea !== undefined ? props.tableArea : <></>}
      </div>
    </div>
  );
}
