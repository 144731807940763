import { mqtt5, auth, iot } from "aws-iot-device-sdk-v2";
import { once } from "events";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
// @ts-ignore
import {
  AWS_REGION,
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_IOT_ENDPOINT,
} from "./settings";

class AWSCognitoCredentialsProvider extends auth.CredentialsProvider {
  constructor(options, expire_interval_in_ms) {
    super();
    this.options = options;

    setInterval(async () => {
      await this.refreshCredentials();
    }, expire_interval_in_ms ?? 3600 * 1000);
  }

  getCredentials() {
    return {
      aws_access_id: this.cachedCredentials?.accessKeyId ?? "",
      aws_secret_key: this.cachedCredentials?.secretAccessKey ?? "",
      aws_sts_token: this.cachedCredentials?.sessionToken,
      aws_region: this.options.Region,
    };
  }

  async refreshCredentials() {
    //console.log("Fetching Cognito credentials");
    this.cachedCredentials = await fromCognitoIdentityPool({
      // Required. The unique identifier for the identity pool from which an identity should be
      // retrieved or generated.
      identityPoolId: this.options.IdentityPoolId,
      clientConfig: { region: this.options.Region },
    })();
  }
}

export function createClient(provider) {
  let wsConfig = {
    credentialsProvider: provider,
    region: AWS_REGION,
  };

  let builder =
    iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
      AWS_IOT_ENDPOINT,
      wsConfig
    );

  let client = new mqtt5.Mqtt5Client(builder.build());

  client.on("error", (error) => {
    //console.log("Error event: " + error.toString());
  });

  client.on("messageReceived", (eventData) => {
    //console.log("Message Received event: " + JSON.stringify(eventData.message));
    if (eventData.message.payload) {
      //console.log("  with payload: " + toUtf8(eventData.message.payload));
    }
  });

  client.on("attemptingConnect", (eventData) => {
    //console.log("Attempting Connect event");
  });

  client.on("connectionSuccess", (eventData) => {
    //console.log("Connection Success event");
    //console.log("Connack: " + JSON.stringify(eventData.connack));
    //console.log("Settings: " + JSON.stringify(eventData.settings));
  });

  client.on("connectionFailure", (eventData) => {
    //console.log("Connection failure event: " + eventData.error.toString());
  });

  client.on("disconnection", (eventData) => {
    //console.log("Disconnection event: " + eventData.error.toString());
    if (eventData.disconnect !== undefined) {
      //console.log("Disconnect packet: " + JSON.stringify(eventData.disconnect));
    }
  });

  client.on("stopped", (eventData) => {
    //console.log("Stopped event");
  });

  return client;
}

export async function startMqtt5Connection() {
  /** Set up the credentialsProvider */
  const provider = new AWSCognitoCredentialsProvider({
    IdentityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
    Region: AWS_REGION,
  });
  /** Make sure the credential provider fetched before setup the connection */
  await provider.refreshCredentials();

  const client = createClient(provider);

  const attemptingConnect = once(client, "attemptingConnect");
  const connectionSuccess = once(client, "connectionSuccess");

  client.start();

  await attemptingConnect;
  await connectionSuccess;

  return client;
}

export async function subscribeMqtt5(client, topic_name) {
  const suback = await client.subscribe({
    subscriptions: [{ qos: mqtt5.QoS.AtLeastOnce, topicFilter: topic_name }],
  });
  //console.log(`subscribe ${topic_name} now`);
  //console.log("Subscribe result: " + JSON.stringify(suback));

  return suback;
}

export async function publishMqtt5(client, topic_name, payload) {
  const ret = await client.publish({
    qos: mqtt5.QoS.AtMostOnce,
    topicName: topic_name,
    payload: payload,
  });
  //console.log("Publish result: " + JSON.stringify(ret));

  return ret;
}

export async function closeConnection(client) {
  const disconnection = once(client, "disconnection");
  const stopped = once(client, "stopped");

  client.stop();
  await disconnection;
  await stopped;
}
