import "./Heatmap.css";
import { scaleLinear } from "d3";
import { isSmartPhone } from "../utils/judgeDevice";

const RECTANGLE_SIZE = isSmartPhone() ? 10 : 20;

const temperature2color = scaleLinear()
  .domain([20, 27, 35])
  .range(["blue", "yellow", "red"]);

export function Heatmap(props) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ scale: 1, padding: "20px" }}>
        {props.data.map((data_row, row_idx) => {
          return (
            <div
              key={`heatmap-row-${row_idx}`}
              style={{
                padding: 0,
                margin: 0,
                height: RECTANGLE_SIZE,
                width: isSmartPhone() ? 350 : 700,
              }}
            >
              {data_row.map((data_cell, col_idx) => {
                return (
                  <div
                    key={`heatmap-row-${row_idx}-${col_idx}`}
                    style={{
                      width: RECTANGLE_SIZE,
                      height: RECTANGLE_SIZE,
                      backgroundColor: temperature2color(data_cell),
                      display: "inline-block",
                      padding: 0,
                      margin: 0,
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
