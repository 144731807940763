//16進数の配列からヒートマップを作成する。
//int16の配列に変換可能な文字列の配列を、数字の配列に変換する
export function uint16ListToThermoMap(strList) {
  //int16に変換可能な文字列の配列を、数字の配列に変換する
  const tmp_list = strList.match(/.{4}/g);
  const mapData = str2NumberMap(tmp_list);
  return mapData;
}

const str2NumberMap = (strList) => {
  const tmp = [];
  strList.map((value, index) => {
    if (index > 0) {
      tmp.push(parseInt(value, 16) / 10.0); //値は温度*10で文字列の配列から渡されるため、10で割って温度の値を求める。
    }
    return true;
  });

  //配列を指定の数ごとに分割する
  const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number);
    return new Array(length)
      .fill()
      .map((_, i) => array.slice(i * number, (i + 1) * number));
  };

  const data = sliceByNumber(tmp, 32);
  return data;
};
