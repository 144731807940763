import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import "./tabs.css";
import "./App.css";
import ThermoSensorDemo from "./demos/ThermoSensorDemo";
import { startMqtt5Connection } from "./utils/connect_aws.js";
import { isSmartPhone } from "./utils/judgeDevice.js";

const sensorList = ["THERMO"];

export default function App() {
  const [sensor, setSensor] = useState(sensorList[0]);
  const [client, setClient] = useState(null);

  // Create Mqtt5 client.
  useEffect(() => {
    (async () => {
      const createdClient = await startMqtt5Connection();
      setClient(createdClient);
    })();
  }, []);

  const DemoIcon = (props) => {
    switch (props.startIconName) {
      case sensorList[0]:
        return (
          <LocalFireDepartmentIcon
            className="sensorIcon"
            style={{
              color: props.startIconName === sensor ? "#1976d2" : "white",
            }}
          />
        );
      default:
        return <div>There is no demonstration for the sensor.</div>;
    }
  };

  return (
    <div className="appRoot">
      {isSmartPhone() ? (
        <div></div>
      ) : (
        <div className="sideMenu">
          <h3>MCU Dev Board Demo</h3>
          <div
            style={{
              border: "solid",
              borderColor: "white",
              borderWidth: "1px",
              width: "90%",
              marginLeft: "3%",
            }}
          />
          <div className="sensorListContainer">
            {sensorList.map((s) => {
              return (
                <Button
                  key={"button-" + s}
                  startIcon={<DemoIcon startIconName={s} />}
                  onClick={(e) => {
                    setSensor(s);
                  }}
                >
                  <div style={{ color: s === sensor ? "#1976d2" : "white" }}>
                    {s}
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      )}

      <div className="sensorWindow">
        <div className="sensorTitle">
          <h1>{sensor}</h1>
        </div>
        <div
          style={{
            padding: "10px",
            flexWrap: "wrap",
            flexDirection: "row",
            display: "flex",
            transform: sensor === sensorList[0] ? "scale(0.8)" : "scale(1)",
            transformOrigin: "top left",
          }}
        >
          <ThermoSensorDemo
            visibility="flex"
            isSummary={false}
            client={client}
          />
        </div>
      </div>
    </div>
  );
}
